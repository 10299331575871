<template>
  <div class="main">
    <heads></heads>
    <ctdkbanners></ctdkbanners>

    <div class="cons">
      <div class="qkxq qkxq-wz">
        <div class="qkxq-title">期刊<span>详情</span></div>
        <div class="qkxq-con">
          <div class="qkxq-con-left">
            <div class="qkxq-con-left-img">
              <el-image :src="srcurl" alt="" />
            </div>
            <div class="qkxq-con-left-xq">
              <div class="qkxq-con-left-xq-title">《{{ obj.Title }}》</div>
              <div
                :class="
                  isqkjj == false
                    ? 'qkxq-con-left-xq-js-ss'
                    : 'qkxq-con-left-xq-js'
                "
              >
                {{ obj.qkjj }}
                <div class="qkxq-con-left-xq-js-ckgd" @click="isqkjj = !isqkjj">
                  {{ isqkjj == false ? "展开" : "收起" }}
                </div>
              </div>
              <div class="qkxq-con-left-xq-jscon">
                <div class="qkxq-con-left-xq-jscon-list">
                  <span>出版周期：</span>{{ obj.cbzq }}
                </div>
                <div class="qkxq-con-left-xq-jscon-list">
                  <span>语种：</span>{{ obj.yuzhong }}
                </div>
                <div class="qkxq-con-left-xq-jscon-list">
                  <span>影响因子：</span>{{ obj.yxyz }}
                </div>
                <div class="qkxq-con-left-xq-jscon-list">
                  <span>国内刊号：</span>{{ obj.gnkh }}
                </div>
                <div class="qkxq-con-left-xq-jscon-list">
                  <span>国际刊号：</span>{{ obj.gjkh }}
                </div>
              </div>
              <div class="qkxq-con-left-xq-jscon2">
                <div
                  class="qkxq-con-left-xq-jscon2-list"
                  v-if="obj.zylm && obj.zylm != ''"
                  :title="obj.zylm"
                >
                  <span>♦ 主要栏目：</span>{{ obj.zylm }}
                </div>
                <div
                  class="qkxq-con-left-xq-jscon2-list"
                  v-if="obj.Award && obj.Award != ''"
                  :title="obj.Award"
                >
                  <span>♦ 获奖情况：</span>{{ obj.Award }}
                </div>
                <div class="qkxq-con-left-xq-jscon2-list">
                  <span>♦ 收录情况：</span>
                  <span v-for="(e, i) in obj.shouluqingkuang" :key="i">
                    <a
                      target="_blank"
                      v-if="e.split('：')[1] != ''"
                      style="margin-right: 10px"
                      :href="e.split('：')[1]"
                      >{{ e.split("：")[0] }}</a
                    >
                  </span>
                  <span class="tipsyuyan">
                    <span>&lt;</span>
                    <span>查看更新情况</span>
                  </span>
                </div>
                <div
                  class="qkxq-con-left-xq-jscon2-list"
                  v-if="obj.CorePeriodical && obj.CorePeriodical != ''"
                  :title="obj.CorePeriodical"
                >
                  <span>♦ 期刊情况：</span>{{ obj.CorePeriodical }}
                </div>
                <div
                  class="qkxq-con-left-xq-jscon2-list"
                  v-if="obj.zgorg && obj.zgorg != ''"
                  :title="obj.zgorg"
                >
                  <span>♦ 主管单位：</span>{{ obj.zgorg }}
                </div>
                <div
                  class="qkxq-con-left-xq-jscon2-list"
                  v-if="obj.zborg && obj.zborg != ''"
                  :title="obj.zborg"
                >
                  <span>♦ 主办单位：</span>{{ obj.zborg }}
                </div>
              </div>
            </div>
          </div>
          <div class="qkxq-con-right">
            <div class="qkxq-con-right-img" @click="clicktougaotuijian">
              <el-image src="/img/detail/tougaotuijian.jpg" alt="" />
            </div>
            <!-- <div class="qkxq-con-right-img" @click="clickxiangtatougao">
              <el-image src="/img/detail/xiangtatougao.jpg" alt="" />
            </div> -->
            <div class="qkxq-con-right-lxfs">
              <div class="qkxq-con-right-lxfs-title">联系方式</div>
              <div class="qkxq-con-right-lxfs-con">
                <div
                  class="qkxq-con-right-lxfs-list"
                  v-if="obj.zhubian && obj.zhubian != ''"
                >
                  <span>主编：</span>{{ obj.zhubian }}
                </div>
                <div
                  class="qkxq-con-right-lxfs-list"
                  v-if="obj.tel && obj.tel != ''"
                >
                  <span>电话：</span>{{ obj.tel }}
                </div>
                <div
                  class="qkxq-con-right-lxfs-list"
                  v-if="obj.yzbm && obj.yzbm != ''"
                >
                  <span>邮编：</span>{{ obj.yzbm }}
                </div>
                <div
                  class="qkxq-con-right-lxfs-list"
                  v-if="obj.dzyx && obj.dzyx != ''"
                >
                  <span>邮箱：</span>{{ obj.dzyx }}
                </div>
                <div
                  class="qkxq-con-right-lxfs-list"
                  v-if="obj.address && obj.address != ''"
                >
                  <span>地址：</span>{{ obj.address }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 发文分析  qikanid  Id=13010 or Id>13732 -->
      <div class="qksx" v-if="qikanid != 13010 && qikanid <= 13732">
        <div class="qksx-list">
          <div class="qksx-list-title">
            <div class="qksx-list-title-icon"></div>
            <div class="qksx-list-title-wz">发文分析</div>
          </div>
          <div class="qksx-list-con">
            <div class="qksx-list-cons qksx-list-cons-33" v-if="obj.kz7">
              <div class="qksx-list-cons-title">发文类型：</div>
              <div class="qksx-list-cons-nr">
                <div class="qksx-list-cons-nrs">
                  {{
                    obj.kz7 == 1
                      ? "学术"
                      : obj.kz7 == 2
                      ? "学术+科普"
                      : obj.kz7 == 3
                      ? "科普"
                      : "未知"
                  }}
                </div>
              </div>
            </div>
            <div class="qksx-list-cons qksx-list-cons-33">
              <div class="qksx-list-cons-title">年刊文量：</div>
              <div class="qksx-list-cons-nr">
                <div class="qksx-list-cons-nrs">{{ obj.wznum }}篇</div>
              </div>
            </div>
            <div class="qksx-list-cons qksx-list-cons-33">
              <div class="qksx-list-cons-title">单期页码：</div>
              <div class="qksx-list-cons-nr">
                <div class="qksx-list-cons-nrs">{{ obj.maxpage }}页</div>
              </div>
            </div>
            <div class="qksx-list-cons" v-if="dyfblistsx.length > 0">
              <div class="qksx-list-cons-title">发文地域：</div>
              <div class="qksx-list-cons-nr">
                <div
                  class="qksx-list-cons-nrs"
                  v-for="(item, key) in dyfblistsx"
                  :key="key"
                >
                  {{ item.name }}（{{ (item.bl * 100).toFixed(2) }}%）
                </div>
              </div>
            </div>
            <div class="qksx-list-cons" v-if="fwjglist.length > 0">
              <div class="qksx-list-cons-title">机构特征：</div>
              <div class="qksx-list-cons-nr">
                <div
                  class="qksx-list-cons-nrs"
                  v-for="(item, key) in fwjglist"
                  :key="key"
                >
                  {{ item.word }}
                </div>
              </div>
            </div>
            <div class="qksx-list-cons" v-if="fwrmclist.length > 0">
              <div class="qksx-list-cons-title">发文热门词：</div>
              <div class="qksx-list-cons-nr">
                <div
                  class="qksx-list-cons-nrs"
                  v-for="(item, key) in fwrmclist"
                  :key="key"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="qksx-list-cons" v-if="fwphlist.length > 0">
              <div class="qksx-list-cons-title">文章分类：</div>
              <div class="qksx-list-cons-nr">
                <span v-if="wzflsourcs.length > 0"
                  ><div
                    class="qksx-list-cons-nrs"
                    v-for="(item, key) in wzflsourcs"
                    :key="key"
                  >
                    {{ item.key }}（{{ (item.value * 100).toFixed(2) }}%）
                  </div></span
                >
                <div
                  class="qksx-list-cons-nrs qksx-list-cons-nrs-tsys"
                  @click="iswzfl = !iswzfl"
                >
                  {{ iswzfl == false ? "展开更多" : "收起全部" }}
                </div>
              </div>
              <div
                class="qksx-list-cons-nr"
                v-if="iswzfl"
                style="padding-left: 100px"
              >
                <div
                  class="qksx-list-cons-nrs"
                  v-for="(item, key) in fwphlist"
                  :key="key"
                >
                  {{ item.flname }}[{{ item.name }}]（{{
                    (item.bl * 100).toFixed(2)
                  }}%）
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="qksx-list xsqk" v-if="xsqklist.length > 0">
          <div class="qksx-list-title">
            <div class="qksx-list-title-icon"></div>
            <div class="qksx-list-title-wz">相似期刊</div>
          </div>
          <div class="qksx-list-con xsqk-con">
            <div
              class="xsqk-list"
              v-for="(item, key) in xsqklist"
              :key="key"
              @click="ckqkxq(item.Id)"
            >
              <el-image
                style="width: 98px; height: 128px; border: 1px solid #eee"
                :title="item.Title"
                :src="proxy.$imgurl + '/qikanfmpic/' + item.pycode + '.jpg'"
              ></el-image>
              <div class="xsqk-list-title">{{ item.Title }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="qkxq fenlei">
        <el-tabs type="border-card">
          <el-tab-pane
            v-if="
              wzxqobj.wzxqarr &&
              wzxqobj.wzxqarr.length > 0 &&
              qikanid != 13010 &&
              qikanid <= 13732
            "
            label="典型文献"
          >
            <div class="fenlei-wzlist">
              <div v-for="(item, index) in wzxqobj.wzxqarr" :key="index">
                <router-link
                  :to="{ name: '/xkzs/delwz', query: { id: item.Id } }"
                >
                  <div class="fenlei-wzlists">
                    <div class="fenlei-wzlists-title">
                      {{ item.arttitle }}
                    </div>
                    <div class="fenlei-wzlists-writer">
                      {{ writeritfilter(item.writer) }}-{{item.org}}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="obj.hzsinfo && qikanid != 13010 && qikanid <= 13732"
            label="学者H指数详情"
          >
            <div class="qkxq-con">
              <div class="zhishu-list">
                <div style="width: 1160px; height: 600px" id="myChart"></div>
              </div>
              <div class="hzhishushuom">学者H指数区间</div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="obj.hzsinfo && qikanid != 13010 && qikanid <= 13732"
            label="机构H指数详情"
          >
            <div class="qkxq-con">
              <div class="zhishu-list">
                <div style="width: 1160px; height: 600px" id="myChartjg"></div>
              </div>
              <div class="hzhishushuom">机构H指数区间</div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="obj.jjbv > 0 && qikanid != 13010 && qikanid <= 13732"
            label="基金占比"
          >
            <div class="jjzb">
              <div style="width: 1160px; height: 600px" id="myChartjjzb"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="dyfblist.length > 0 && qikanid != 13010 && qikanid <= 13732"
            label="地域分布"
          >
            <el-table :data="dyfblist" style="width: 100%">
              <el-table-column prop="name" label="区域名称" />
              <el-table-column prop="bl" label="占比" sortable />
            </el-table>
          </el-tab-pane>
          <el-tab-pane
            v-if="ciyundatas.length > 0 && qikanid != 13010 && qikanid <= 13732"
            label="学术方向"
          >
            <!-- <div class="xsfx-table">
              <div class="xsfx-table-title">学术方向关键词列表</div>
              <div class="xsfx-table-tab">
                <el-table :data="ciyundatas" border style="width: 100%">
                  <el-table-column prop="kw" label="关键词" />
                  <el-table-column prop="num" sortable label="数量" />
                </el-table>
              </div>
            </div> -->
            <div class="xsfx">
              <div id="echartsyyy"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="
              fawenduibilist.length > 0 && qikanid != 13010 && qikanid <= 13732
            "
            label="发文对比"
          >
            <div class="xsfx-table">
              <div class="xsfx-table-title"></div>
              <div class="xsfx-table-tab">
                <el-table :data="fawenduibilist" border style="width: 100%">
                  <el-table-column prop="Title" label="期刊名称" width="200" />
                  <el-table-column prop="yxyz" sortable label="影响因子" />
                  <el-table-column prop="cbzq" sortable label="出版周期" />
                  <el-table-column
                    prop="coreperiodical"
                    sortable
                    label="期刊情况"
                  />
                  <el-table-column label="发文偏好" width="400">
                    <el-table-column prop="fwphmc1" label="名称" width="200">
                      <template #default="scope">
                        <div style="width: 100%">
                          <div
                            class="fwph-ul"
                            v-for="(i, key) in scope.row.fwphmc1"
                            :key="key"
                          >
                            <div class="fwph-list fwph-list1">
                              {{ i }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="fwphbl3" label="比例">
                      <template #default="scope">
                        <div style="width: 100%">
                          <div
                            class="fwph-ul"
                            v-for="(i, key) in scope.row.fwphbl3"
                            :key="key"
                          >
                            <div class="fwph-list fwph-list3">
                              {{ (i * 100).toFixed(2) }}%
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column prop="maxpage" sortable label="单期页码" />
                  <el-table-column prop="wznum" sortable label="年刊文量" />
                </el-table>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="投稿须知">
            <div class="tgxq" v-html="obj.updatetime"></div>
          </el-tab-pane>
          <el-tab-pane label="职称">
            <div class="zhicheng">
              <div class="zhicheng-left">
                <el-tree
                  :data="zhihcengdata"
                  :props="defaultProps"
                  node-key="Id"
                  :default-expanded-keys="expandedKeys"
                  @node-click="handleNodeClick"
                />
              </div>
              <div class="zhicheng-right">
                <div class="zhicheng-tips">
                  <!-- <div class="zhicheng-tips-title">
                    <span>选中职称：</span>{{ zhichengname }}
                  </div> -->
                  <div
                    class="zhicheng-tips-title"
                    v-if="zhichengxiangqing.pkmark != ''"
                  >
                    <span>学术论文：</span>{{ zhichengxiangqing.pkmark || 0 }}篇
                  </div>
                  <div
                    class="zhicheng-tips-title"
                    v-if="zhichengxiangqing.hxmark != ''"
                  >
                    <span>科普文章：</span>{{ zhichengxiangqing.hxmark || 0 }}篇
                  </div>
                  <div class="zhicheng-tips-con">
                    <div
                      class="zhicheng-tips-con-title"
                      v-bind="zhichengxiangqing.lysource != ''"
                    >
                      职称要求<a
                        :href="zhichengxiangqing.lysource"
                        target="_blank"
                        >查看来源</a
                      >
                    </div>
                    <div
                      class="zhicheng-tips-con-nr"
                      v-if="zhichengxiangqing.yaoqiu != ''"
                    >
                      <el-input
                        v-model="zhichengxiangqing.yaoqiu"
                        type="textarea"
                        autosize
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="zhicheng-tips-con"
                    v-if="zhichengxiangqing.remark != ''"
                  >
                    <div class="zhicheng-tips-con-title">职称说明</div>
                    <div class="zhicheng-tips-con-nr">
                      <el-input
                        v-model="zhichengxiangqing.remark"
                        type="textarea"
                        autosize
                        disabled
                      />
                    </div>
                  </div>
                  <!-- <div class="zhicheng-btns">
                    <el-button type="success" @click="clicktougao"
                      >我要投稿</el-button
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="稿件要求">
            <div class="gjyq">稿件要求</div>
          </el-tab-pane> -->
        </el-tabs>
      </div>
      <div class="tips">
        以上信息，由域田数据科技根据网络公开资料自动分析生成，仅供参考！
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { reactive, ref, inject, onMounted, getCurrentInstance } from "vue";
import "echarts-wordcloud";
import { get, post } from "@/utils/index";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();

// 期刊id
const qikanid = parseInt(router.currentRoute.value.query.id);
// console.log(qikanid)
// 期刊简介展开
let isqkjj = ref(false);
let iswzfl = ref(false);
// 词云
let $echarts = inject("echarts");
// 返回的数据
let obj = reactive({});
// 文章详情列表
let wzxqobj = reactive({
  wzxqarr: [],
});
// 图片路径
let srcurl = ref("");
// 地域分布数据
let dyfblist = reactive([]);
let dyfblistsx = reactive([]);
let iconsize = ref("14px");
let iconcolor = ref("#f00");
// 发文偏好
let fwphlist = reactive([]);
// 发文对比
let fawenduibilist = reactive([]);
// 文章分类标题  取自发文对比
let wzflsourcs = reactive([]);

// 相似期刊
let xsqklist = reactive([]);

// echarts 学者H指数
let state = reactive({
  option: {
    title: {
      text: "学者H指数占比详情",
      left: "center",
      top: "5%",
      subtext:
        "学者H指数指该学者至多有h篇论文分别被引用了至少h次。学者h指数越高，其论文影响力越大。该期刊发文作者的H指数分布如下。 ",
      subtextStyle: {
        color: "#aaa",
      },
    },

    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "horizontal",
      bottom: "5%",
    },
    series: [
      {
        name: "学者H指数区间占比",
        type: "pie",
        radius: "50%",
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  },
});

// echarts 机构H指数
let statejg = reactive({
  option: {
    title: {
      text: "机构H指数占比详情",
      left: "center",
      top: "5%",
      subtext:
        "机构H指数指该机构至多有h篇论文分别被引用了至少h次。机构h指数越高，其论文影响力越大。该期刊发文机构的H指数分布如下。 ",
      subtextStyle: {
        color: "#aaa",
      },
    },
    legend: {
      orient: "horizontal",
      bottom: "5%",
    },
    series: [
      {
        name: "机构H指数区间占比",
        type: "pie",
        radius: "50%",
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  },
});

// echarts 基金占比
let statejjzb = reactive({
  option: {
    title: {
      text: "基金占比",
      left: "center",
      top: "5%",
    },
    tooltip: {
      trigger: "item",
      // formatter: '{b}:{c} ({d}%)' ,//默认值null，内容格式器
    },
    legend: {
      orient: "horizontal",
      bottom: "5%",
    },
    series: [
      {
        name: "基金占比",
        type: "pie",
        radius: "50%",
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  },
});

// ciyun
let stateciyun = reactive({
  option: {
    series: [
      {
        type: "wordCloud",
        // shape这个属性虽然可配置，但是在词的数量不太多的时候，效果不明显，它会趋向于画一个椭圆
        shape: "circle",
        // 这个功能没用过
        keepAspect: false,
        // maskImage这个是可以自定义背景图片的，词云会按照图片的形状排布，所以有形状限制的时候，最好用背景图来实现，而且，这个背景图一定要放base64的，不然词云画不出来
        // maskImage: '',
        // 下面就是位置的配置
        left: "center",
        top: "center",
        width: "90%",
        height: "90%",
        right: null,
        bottom: null,
        // 词的大小，最小12px，最大60px，可以在这个范围调整词的大小
        sizeRange: [12, 60],
        // 每个词旋转的角度范围
        rotationRange: [-90, 90],
        rotationStep: 45,
        // 词间距，数值越小，间距越小，这里间距太小的话，会出现大词把小词套住的情况，比如一个大的口字，中间会有比较大的空隙，这时候他会把一些很小的字放在口字里面，这样的话，鼠标就无法选中里面的那个小字
        gridSize: 8,
        // 允许词太大的时候，超出画布的范围
        drawOutOfBound: false,
        // 布局的时候是否有动画
        layoutAnimation: true,
        // 这是全局的文字样式，相对应的还可以对每个词设置字体样式
        textStyle: {
          fontFamily: "sans-serif",
          fontWeight: "bold",
          // 颜色可以用一个函数来返回字符串
          color: function () {
            // Random color
            return (
              "rgb(" +
              [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
              ].join(",") +
              ")"
            );
          },
        },
        emphasis: {
          focus: "self",
          textStyle: {
            textShadowBlur: 10,
            textShadowColor: "#333",
          },
        },

        // 数据必须是一个数组，数组是对象，对象必须有name和value属性
        data: [],
      },
    ],
  },
});
let ciyundatas = reactive([]);
// 发文热门词
let fwrmclist = reactive([]);
// 发文机构数据
let objs = reactive({});
let fwjglistyuan = reactive([]);
let fwjglist = reactive([]);

// 职称
const defaultProps = {
  children: "Children",
  label: "Name",
};
let expandedKeys = reactive([]); //默认展开的key数组
let zhihcengdata = reactive([]);
// 职称详情
let zhichengxiangqing = reactive({});
// 职称名字
let zhichengname = ref("");

// 获取期刊下的职称
const getzhicheng = () => {
  get("/journalTitle/GetZcByQkid/" + router.currentRoute.value.query.id, {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // console.log(res);
    if (res.data && res.data.length > 0) {
      res.data.forEach((e) => {
        expandedKeys.push(e.Id);
      });
      zhihcengdata.length == 0;
      zhihcengdata.push(...res.data);
    }
  });
};
getzhicheng();

// 点击树形
const handleNodeClick = (data) => {
  // console.log(data);
  // 如果是最后一级获取该职称详情
  if (data.Children.length == 0) {
    get("/journalTitle/GetDet/" + data.Id).then((res) => {
      Object.assign(zhichengxiangqing, res.data);
    });
  }
};

// 点击投稿推荐
const clicktougaotuijian = () => {
  const url = router.resolve({
    path: "/xkzs/tgpg",
    query: {
      id: router.currentRoute.value.query.id,
    },
  });
  window.open(url.href);
};

// 向他投稿
const clickxiangtatougao=()=>{
  const url = router.resolve({
    path: "/tougao",
    query: {
      id: router.currentRoute.value.query.id,
    },
  });
  window.open(url.href);
}

const getfwjg = () => {
  post("/journalOrganKeywords/getJournalTopWords", {
    journalId: router.currentRoute.value.query.id,
    limit: 10,
  }).then((res) => {
    // console.log(res)
    fwjglist.push(...res.result);
  });
};
getfwjg();

// 获取发文对比
const getfwdb = () => {
  get("/Journal/GetXsqkSjbdList", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    if (typeof res.data == "undefined" || res.data == null) return;
    fawenduibilist.push(...res.data);
    fawenduibilist.map((e) => {
      e.fwph = JSON.parse(e.fwph);
      let fwphtemp = [];
      let fwphtemp2 = [];
      let fwphtemp3 = [];
      e.fwphmc1 = [];
      e.fwphsl2 = [];
      e.fwphbl3 = [];
      e.fwph.forEach((ele) => {
        let fwphtempstr = `${ele.flname}(${ele.name})`;
        fwphtemp.push(fwphtempstr);
        fwphtemp2.push(ele.num);
        fwphtemp3.push(ele.bl);
      });
      e.fwphmc1.push(...fwphtemp);
      e.fwphsl2.push(...fwphtemp2);
      e.fwphbl3.push(...fwphtemp3);
    });
    // console.log(fawenduibilist);
    // 生成文章分类标题
    for (let i = 0; i < 3; i++) {
      if (fawenduibilist[0].fwphmc1[i] && fawenduibilist[0].fwphbl3[i]) {
        let opt = {};
        opt.key = fawenduibilist[0].fwphmc1[i];
        opt.value = fawenduibilist[0].fwphbl3[i];
        wzflsourcs.push(opt);
      }
    }
  });
};
getfwdb();

// 获取相似期刊
const getxsqk = () => {
  get("/Journal/GetXsqkList", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    xsqklist.length = 0;
    if (res.data) {
      res.data.forEach((e, i) => {
        if (i < 10) {
          xsqklist.push(e);
        }
      });
    }
  });
};
getxsqk();

// 查看期刊
const ckqkxq = (id) => {
  const url = router.resolve({
    path: "/xkzs/del",
    query: {
      id: id,
    },
  });
  window.open(url.href);
};

// 获取地域分布
const getdyfb = () => {
  get("/Journal/GetQkAreaDistribution", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // console.log(res)
    dyfblist.push(...res.data);
    res.data.forEach((e, i) => {
      if (i < 5) {
        dyfblistsx.push(e);
      }
    });
  });
};
getdyfb();

// 获取数据
function getList() {
  get("/Journal/GetQkInfo", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // console.log(res);
    Object.assign(obj, res.data);
    obj.shouluqingkuang = obj.slqkdet.split(";\n");
    //投稿须知拼接更新时间
    obj.updatetime = "更新时间：" + obj.updatetime + "<br/>" + obj.kz1;

    srcurl.value = `${proxy.$imgurl}/qikanfmpic/${obj.pycode}.jpg`;

    // 页面标题描述摘要
    document.title = res.data.seotitle;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", res.data.seokeyword);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", res.data.kz11);

    // 发文偏好数据fwphlist
    if (res.data.flhjsonshort) {
      JSON.parse(res.data.flhjsonshort).forEach((e, i) => {
        fwphlist.push(e);
      });
    }

    // 基金占比
    if (obj.jjbv > 0) {
      statejjzb.option.series[0].data = [
        { value: obj.jjbv, name: "基金论文量" },
        { value: (100 - obj.jjbv).toFixed(2), name: "文献量" },
      ];
      setTimeout(() => {
        initeCharjjzb();
      }, 100);
    }

    // 词云数据
    if (res.data.xsfx) {
      let ciyundatastemp = JSON.parse(res.data.xsfx);
      // 倒序取前五作为发文热门词
      ciyundatas.push(...ciyundatastemp);
      ciyundatas.forEach((e) => {
        e.name = e.kw;
        e.value = e.num;
      });

      ciyundatastemp
        .sort((a, b) => (a.num < b.num ? 1 : a.num > b.num ? -1 : 0))
        .forEach((e, i) => {
          if (i < 5) {
            fwrmclist.push(e);
          }
        });

      stateciyun.option.series[0].data = ciyundatas;
      setTimeout(() => {
        ciyun();
      }, 100);
    }

    // echarts数据
    if (obj.hzsinfo && obj.hzsinfo != "-") {
      const zhishuobj = JSON.parse(obj.hzsinfo);
      state.option.series[0].data = [
        { value: zhishuobj.xzh1, name: "10以下" },
        { value: zhishuobj.xzh2, name: "10-20" },
        { value: zhishuobj.xzh3, name: "20以上" },
      ];
      statejg.option.series[0].data = [
        { value: zhishuobj.org1, name: "10以下" },
        { value: zhishuobj.org2, name: "10-20" },
        { value: zhishuobj.org3, name: "20以上" },
      ];
      initeCharts();
      initeChartsjg();
    }
  });
}

// 获取文章列表
function getwzlist() {
  get("/Journal/GetQkarticle", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    console.log(res);
    wzxqobj.wzxqarr = res.data;
  });
}

// 学者H指数绘制
const initeCharts = () => {
  if (document.getElementById("myChart")) {
    let myChart = proxy.$echarts.init(document.getElementById("myChart"));
    myChart.setOption(state.option);
  }
};
// 机构H指数绘制
const initeChartsjg = () => {
  if (document.getElementById("myChartjg")) {
    let myChart = proxy.$echarts.init(document.getElementById("myChartjg"));
    myChart.setOption(statejg.option);
  }
};

// 词云
const ciyun = () => {
  // 词云
  let mychart = proxy.$echarts.init(document.getElementById("echartsyyy"));
  // 这里是官方给出的一些基本的配置项，我做一些说明
  mychart.setOption(stateciyun.option);
};

// 基金占比
const initeCharjjzb = () => {
  if (document.getElementById("myChartjjzb")) {
    let myChart = proxy.$echarts.init(document.getElementById("myChartjjzb"));
    myChart.setOption(statejjzb.option);
  }
};

// 空值
const writeritfilter = (value) => {
  let fhz = value;
  switch (value) {
    case "":
      fhz = "作者不详";
      break;
  }
  return fhz;
};

onMounted(() => {
  getList();
  getwzlist();
});
</script>

<style scoped>
:deep(.el-image) {
  width: 100%;
  height: 100%;
}
:deep(.el-image__inner) {
  width: 100%;
  height: 100%;
}
:deep(.el-tabs--border-card > .el-tabs__content) {
  padding: 20px;
}
.main {
  background: #f4f4f4;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 50px 0;
}
.qkxq {
  background: #fff;
}
.qkxq-wz {
  border: 1px solid #dcdfe6;
}
.qkxq-title {
  width: 100%;
  height: 60px;
  border-left: 2px solid #3860f3;
  box-sizing: border-box;
  padding-left: 20px;
  line-height: 60px;
  color: #3860f3;
  font-weight: bold;
  font-size: 24px;
}
.qkxq-title > span {
  color: #666;
}
.qkxq-con {
  position: relative;
}
.qkxq-con::after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left {
  width: 910px;
  height: auto;
  float: left;
  padding: 20px;
  box-sizing: border-box;
}
.qkxq-con-left:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left-img {
  width: 200px;
  height: 280px;
  float: left;
  box-sizing: border-box;
  border: 1px solid #eee;
}
.qkxq-con-left-xq {
  width: 670px;
  height: auto;
  float: left;
  padding: 0 20px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  font-size: 14px;
}
.qkxq-con-left-xq-title {
  font-size: 20px;
  font-weight: bold;
}
.qkxq-con-left-xq-js {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 10px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: justify;
  margin-bottom: 20px;
  color: #888;
  line-height: 22px;
}
.qkxq-con-left-xq-js-ss {
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  box-sizing: border-box;
  text-align: justify;
  margin-bottom: 30px;
  padding-right: 40px;
  color: #888;
  line-height: 22px;
  overflow: hidden;
}
.qkxq-con-left-xq-js-ckgd {
  position: absolute;
  right: 0;
  bottom: -4px;
  color: #3860f3;
  cursor: pointer;
}
.qkxq-con-left-xq-jscon {
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.qkxq-con-left-xq-jscon:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left-xq-jscon-list {
  float: left;
  width: 33.33%;
  display: flex;
  align-items: center;
  color: #3860f3;
  margin-bottom: 5px;
}
.qkxq-con-left-xq-jscon-list > span {
  width: 90px;
  display: block;
}
.qkxq-con-left-xq-jscon2:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left-xq-jscon2-list {
  width: 100%;
  float: left;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qkxq-con-left-xq-jscon2-list > span {
  width: 100px;
  color: #888;
  display: inline;
}
.qkxq-con-left-xq-jscon2-list-50 {
  width: 50%;
}
.qkxq-con-right {
  width: 287px;
  float: left;
  padding: 20px 20px 20px 0;
  box-sizing: border-box;
}
.qkxq-con-right-img {
  cursor: pointer;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}
.qkxq-con-right-img:hover {
  border: 1px solid #3860f3;
}
.qkxq-con-right-lxfs {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}
.qkxq-con-right-lxfs-con {
  font-size: 14px;
  padding-top: 10px;
}
.qkxq-con-right-lxfs-con:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-right-lxfs-list {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.qkxq-con-right-lxfs-title {
  font-size: 18px;
  font-weight: bold;
  color: #888;
}

.hzhishushuom {
  position: absolute;
  bottom: 60px;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.zhishu {
  margin-top: 30px;
}
.zhishu-list {
  float: left;
  width: 100%;
  height: 600px;
}
.fenlei {
  margin-top: 20px;
}
.fenlei-title {
  width: 100%;
  height: 40px;
  background: #e2e2e2;
}
.fenlei-titles {
  width: 100px;
  height: 40px;
  float: left;
  text-align: center;
  line-height: 40px;
  color: #333;
  cursor: pointer;
}
.fenlei-wzlist:after {
  content: "";
  display: table;
  clear: both;
}
.fenlei-wzlist a:link,
.fenlei-wzlist a:visited {
  color: #333;
  text-decoration: none;
}
.fenlei-wzlist a:hover {
  color: #3860f3;
}
.fenlei-wzlists {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: left;
  background: url("/img/detail/xiaoyuandian.jpg");
}
.fenlei-wzlists-title,
.fenlei-wzlists-writer {
  height: 36px;
  line-height: 36px;
  background: #fff;
  font-size: 14px;
  cursor: pointer;
}
.fenlei-wzlists-writer{
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}
.fenlei-wzlists-title:hover {
  color: #3860f3;
}
.fenlei-titles-avtive {
  background: #393c41;
  color: #fff;
}
.tgxq {
  font-size: 14px;
}
.hzs-null {
  font-size: 14px;
}
.xsfx,
#echartsyyy {
  width: 1160px;
  height: 600px;
}

.qksx {
  width: 100%;
  height: auto;
  background: #fff;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #dcdfe6;
}
.qksx-list-title {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
}
.qksx-list-title-icon {
  width: 5px;
  height: 20px;
  background: #3860f3;
  margin-right: 10px;
}
.qksx-list-con {
  font-size: 14px;
  padding: 20px 0 0 0;
}
.qksx-list-con:after {
  content: "";
  display: table;
  clear: both;
}
.xsqk-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qksx-list-cons {
  width: 100%;
  height: auto;
  float: left;
}
.qksx-list-cons-33 {
  width: 33.33%;
}
.qksx-list-cons:after {
  content: "";
  display: table;
  clear: both;
}

.qksx-list-cons-title {
  float: left;
  width: 100px;
  height: 30px;
  line-height: 30px;
  color: #888;
}
.qksx-list-cons-nr {
  float: left;
  width: calc(100% - 100px);
  min-height: 30px;
}
.qksx-list-cons-nr:after {
  content: "";
  display: table;
  clear: both;
}
.qksx-list-cons-nrs {
  width: auto;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #d9ecff;
  background: #f7fbff;
  float: left;
  padding: 0 5px;
  margin: 0 5px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qksx-list-cons-nrs-tsys {
  border: 1px solid #f55b5b;
  background: #ffeded;
  color: #f00;
  cursor: pointer;
}
.xsqk {
  margin-top: 10px;
}
.xsqk-list {
  cursor: pointer;
}
.xsqk-list-title {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
}
.tips {
  color: #999;
  font-size: 12px;
  padding-top: 20px;
}
.xsfx-table-title {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
}

.fwph-ul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fwph-list {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  margin: 0 0 5px 0;
}
.fwph-list1 {
  width: 220px;
}
.fwph-list2 {
  width: 50px;
}
.fwph-list3 {
  width: 80px;
}
.tipsyuyan {
  display: inline-block;
  height: 19px;
}

.zhicheng:after {
  content: "";
  display: table;
  clear: both;
}
.zhicheng-left {
  width: 49%;
  float: left;
}
.zhicheng-right {
  width: 50%;
  float: right;
}
.zhicheng-tips {
  background: #f5f7fa;
  padding: 20px 20px 10px 20px;
  font-size: 14px;
  margin-bottom: 20px;
  color: #333;
}
.zhicheng-tips-title {
  padding-bottom: 10px;
}
.zhicheng-tips-title > span {
  font-weight: bold;
}
.zhicheng-tips-con {
  margin-bottom: 10px;
}
.zhicheng-tips-con-title {
  font-weight: bold;
  padding-bottom: 5px;
}
.zhicheng-tips-con-title a {
  margin-left: 10px;
  font-weight: 500;
}
.zhicheng-tips-con-title a:link,
.zhicheng-tips-con-title a:visited {
  text-decoration: none;
  color: #409eff;
}
.zhicheng-tips-con-title a:hover {
  text-decoration: underline;
}
</style>
